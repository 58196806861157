
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { Loader, Icon } from '../../../../components/ui';
import { ProfileCard } from '../../../../components/common';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useStores } from '@/store/Stores';

export default {
  components: {
    ProfileCard,
    InputNumber,
    InputText,
    Loader,
    Button,
    Icon
  },
  setup() {
    const { companyStaffUserStore, companyStaffUserStoreState } = useStores();

    const route = useRoute();
    const data = computed(() => companyStaffUserStoreState.data);
    const { t } = useI18n();
    const toast = useToast();

    const rules = {
      title: { required },
      email: { required, email },
      name: { required },
      phone: { required },
      address: { required },
      credits: {},
      tokens: {},
      is_active: {}
    };

    const v$ = useVuelidate(rules, data);

    companyStaffUserStore.init(route.params.companyMemberID);

    const handleSubmit = (isFormValid) => {
      v$.value.$touch();

      companyStaffUserStore.saveChanges(data.value, toast, t);
    };

    const toggleBlock = () => {
      companyStaffUserStore.toggleBlock();
    };

    return {
      v$,
      state: companyStaffUserStoreState,
      handleSubmit,
      toggleBlock
    };
  }
};
