
import { ref, watch } from 'vue';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRoute, useRouter } from 'vue-router';

import { Icon, Empty, Loader, StatusBool } from '../../../../components/ui';
import { Paginator } from '../../../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    InputText,
    Button,
    Icon,
    DataTable,
    Column,
    Loader,
    Empty,
    StatusBool,
    Paginator
  },
  setup() {
    const { companyStaffStore, companyStaffStoreState } = useStores();
    const route = useRoute();
    const router = useRouter();

    const search = ref();

    companyStaffStore.init({ company: route.params.companyID, per_page: 15, page: route.query.page, search: search.value });

    watch(route, () => {
      companyStaffStore.init({ company: route.params.companyID, per_page: 15, page: route.query.page, search: search.value });
    });

    watch(search, (newValue) => {
      // const query = {...route.query, ...{ search: newValue }}
      router.push({ name: route.name as any, query: { search: newValue } });
    });
    return {
      search,
      state: companyStaffStoreState
    };
  }
};
