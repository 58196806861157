import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-field _base-search-size" }
const _hoisted_2 = { class: "p-input-icon-right" }
const _hoisted_3 = { class: "_relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_Column = _resolveComponent("Column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StatusBool = _resolveComponent("StatusBool")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toolbar, { class: "mb-4" }, {
      left: _withCtx(() => [
        _createElementVNode("label", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_InputText, {
              type: "search",
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              placeholder: _ctx.$t('search')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_Icon, {
              name: "zoom",
              className: "p-field-icon"
            })
          ])
        ])
      ]),
      right: _withCtx(() => [
        _createVNode(_component_Button, null, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { name: "plus" }),
            _createElementVNode("span", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('company_page.add_new_employee')), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      ($setup.state.isLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: ""
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_DataTable, {
        value: $setup.state.data,
        loading: $setup.state.isLoading,
        responsiveLayout: "scroll"
      }, {
        empty: _withCtx(() => [
          _createVNode(_component_Empty)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, { header: "id" }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.id), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: _ctx.$t('company_page.name')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_router_link, {
                class: "_link",
                to: { name: 'CompanyMember', params: { companyMemberID: slotProps.data.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(slotProps.data.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('company_page.email')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.email), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('company_page.phone')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.phone), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('company_page.credits')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.credits), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('company_page.status')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_StatusBool, {
                isActive: slotProps.data.is_active
              }, null, 8, ["isActive"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value", "loading"])
    ]),
    _createVNode(_component_Paginator, {
      currentPage: $setup.state.meta.current_page || _ctx.$route.query.page || 1,
      total: $setup.state.meta.total,
      lastPage: $setup.state.meta.last_page,
      perPage: $setup.state.meta.perPage,
      count: $setup.state.meta.to
    }, null, 8, ["currentPage", "total", "lastPage", "perPage", "count"])
  ], 64))
}